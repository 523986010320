body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body {
	height: 100%;
	margin: 0;
}

#root {
	position: relative;
	min-height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* Responsive BR */
@media screen and (min-width: 600px)  {
	.mobile-break { display: none; }
}

/* XS */
@media screen and (min-width: 0px) {
	html {
		font-size: 14px;
	}
}
/* SM */
@media screen and (min-width: 600px) {
	html {
		font-size: 15px;
	}
}
/* MD */
@media screen and (min-width: 960px) {
	html {
		font-size: 16px;
	}
}
/* LG */
@media screen and (min-width: 1280px) {
	html {
		font-size: 17px;
	}
}
/* XL */
@media screen and (min-width: 1920px) {
	html {
		font-size: 19px;
	}
}
